import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import slugify from 'slugify';
import { useStaticQuery, graphql } from 'gatsby';

function Metadata({ lang, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            programName
            metaDescription
            openGraphDescription
            openGraphImg
            tweetText
          }
        }
      }
    `
  );

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        class: slugify(title, { lower: true }),
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.programName}`}
      meta={[
        {
          name: `description`,
          content: site.siteMetadata.metaDescription,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl,
        },
        {
          property: `og:title`,
          content: site.siteMetadata.programName,
        },
        {
          property: `og:description`,
          content: site.siteMetadata.openGraphDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: [
            `${site.siteMetadata.siteUrl}${site.siteMetadata.openGraphImg}`,
          ],
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:description`,
          content: site.siteMetadata.tweetText,
        }
      ]}
    />
  );
}

Metadata.defaultProps = {
  lang: `en`,
};

Metadata.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Metadata;
